import {defineMessages} from 'react-intl';

const messages = defineMessages({
  firstName: {
    id: 'CentralLogin.signUp.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'CentralLogin.signUp.lastName',
    defaultMessage: 'Last Name',
  },
  emailPlaceHolder: {
    id: 'CentralLogin.signUp.emailPlaceHolder',
    defaultMessage: 'Email',
  },
  passwordPlaceHolder: {
    id: 'CentralLogin.signUp.passwordPlaceHolder',
    defaultMessage: 'Password',
  },
  submitting: {
    id: 'CentralLogin.signUp.isSubmitting',
    defaultMessage: 'One Moment...',
  },
  signUp: {
    id: 'CentralLogin.social.signUp.submitButton',
    defaultMessage: 'Connect',
  },
  signIn: {
    id: 'CentralLogin.signUp.signInLink',
    defaultMessage: 'Sign in',
  },
  haveStubHubAccount: {
    id: 'CentralLogin.signUp.haveStubHubAccount',
    defaultMessage: 'Already have one?',
  },
  title: {
    id: 'CentralLogin.social.signUp.title',
    defaultMessage: 'Connect StubHub to {socialType}',
  },
  email_box: {
    id: 'CentralLogin.signUp.emailSignUp',
    defaultMessage: 'Send me emails about upcoming events',
  },
  maxLengthHint: {
    id: 'CentralLogin.signUp.name.length.error',
    defaultMessage: 'Your name needs to be {maxLength} characters only!',
  },
  emptyfirstname: {
    id: 'CentralLogin.signUp.emptyFirstName',
    defaultMessage: 'Whoops! We need first name.',
  },
  emptylastname: {
    id: 'CentralLogin.signUp.emptyLastName',
    defaultMessage: 'Whoops! We need last name.',
  },
  emptypassword: {
    id: 'CentralLogin.signUp.emptyPassword',
    defaultMessage: 'Whoops! We need a password.',
  },
  invalidpwd: {
    id: 'CentralLogin.signUp.invalidPWD',
    defaultMessage:
      'Whoops! Your password needs to be 8 to 20 characters; a combo of numbers, letters, and/or special characters; and cannot be your email address.',
  },
  generic_error: {
    id: 'CentralLogin.signUp.genericError',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
  account_exists: {
    id: 'CentralLogin.signUp.accountExists',
    defaultMessage: 'Looks like your email address is already registered with StubHub.',
  },
  email_pwd_error: {
    id: 'CentralLogin.register.emailPWDError',
    defaultMessage: 'Enter a valid Email and Password',
  },
  invalid_password: {
    id: 'CentralLogin.register.invalidPWD',
    defaultMessage:
      'Oops! Your password needs to be 8-20 characters; a combo of numbers, letters, and/or special characters; and cannot be your email address.',
  },
  instruction: {
    id: 'CentralLogin.social.signUp.instruction',
    defaultMessage:
      'We’ll use the email you provided for transaction-related communications, so please make sure you’re using an active one.',
  },
  returnTo: {
    id: 'CentralLogin.social.signUp.returnTo',
    defaultMessage: 'Changed your mind? Return to',
  },
  signUpTitle: {
    id: 'CentralLogin.social.signUp.title',
    defaultMessage: 'Connect StubHub to {socialType}',
  },
});

export default messages;
