import React from 'react';

import FormattedLink from '@stubhub/react-formatted-link';

export const UserAgreementLink = (chunks) => (
    <FormattedLink href="/legal/?section=ua" target="_blank">
        {chunks}
    </FormattedLink>
);
export const PrivacyNoticeLink = (chunks) => (
    <FormattedLink href="/legal/?section=pp" target="_blank">
        {chunks}
    </FormattedLink>
);
