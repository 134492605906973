/* eslint-disable react/no-unused-class-component-methods */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import qs from 'query-string';
import {FormattedMessage, injectIntl} from 'react-intl';

import {publish} from '@stubhub/pubsub-utils';
import FormattedLink from '@stubhub/react-formatted-link';
import {connect} from '@stubhub/react-store-provider';

import {SIGNUP_EVENTS} from '../../apps/events-enum';
import {getRedirectUrl} from '../../util/helper';
import {UserAgreementLink,PrivacyNoticeLink} from '../react-legal-terms';
import termsMessages from '../react-legal-terms/termsMessages';
import Input from '../react-ui-components/input';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import validate from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';
import Checkbox from '../react-uikit-checkbox';
import {Col, Row} from '../react-uikit-layout';

import controller from './controller';
import messages from './messages';

const MAX_NAME_LENGTH = 50;

@injectIntl
export class SocialSignUpForm extends Component {
  constructor(props) {
    super(props);
    this.maxLengthHint = props.intl.formatMessage(messages.maxLengthHint, {maxLength: MAX_NAME_LENGTH});
    this.emptyfirstname = props.intl.formatMessage(messages.emptyfirstname);
    this.emptylastname = props.intl.formatMessage(messages.emptylastname);
    this.invalidpwd = props.intl.formatMessage(messages.invalidpwd);
    const {firstName, lastName, email} = this.props.socialData;
    this.state = {
      first_name: firstName,
      last_name: lastName,
      email,
      marketing_email_opt_in: 'Y',
      errors: {},
      country: null,
      userDestination: null,
      showError: false,
    };
  }

  static propTypes = {
    submitting: PropTypes.bool,
    intl: PropTypes.object,
    errorMsgKey: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    submitting: false,
  };

  static contextTypes = {
    globalRegistry: PropTypes.object,
    cookies: PropTypes.object,
  };

  componentDidMount() {
    const {globalRegistry} = this.context;
    const country = globalRegistry && globalRegistry.getCurrentCountry && globalRegistry.getCurrentCountry();
    const removeDefaultPhone =
      globalRegistry &&
      globalRegistry.getFeatureValue &&
      globalRegistry.getFeatureValue('common.register.removeDefaultPhone');
    const countryDestinationMapping = {
      GB: 'UK',
    };
    const userDestination = countryDestinationMapping[country] || country;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      country,
      userDestination,
      removeDefaultPhone,
    });
    publish(SIGNUP_EVENTS.SOCIAL, getRedirectUrl(this.props.location));
  }

  handleInputChange = (event) => {
    const {target} = event;
    const {name} = target;
    const {value} = target;

    this.setState({
      [name]: value,
      showError: false,
    });
  };
  handleEmailChange = (state) => {
    const newState = {
      ...this.state,
      showError: false,
      email: state.email,
      errors: {
        ...this.state.errors,
        ...{email: state.errors},
      },
    };
    this.setState(newState);
  };
  handleCheckboxChange = (state) => {
    this.setState({
      showError: false,
      marketing_email_opt_in: state ? 'Y' : 'N',
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({showError: false});
    const s = __CLIENT__ && window.s;
    const {
      location: {search},
    } = this.props;
    const parsed = qs.parse(search.substring(1));
    const redirect = parsed && parsed.redirect ? parsed.redirect : null;

    this.props.processSocialSignUpLogin({
      ...this.props.socialData,
      email: this.state.email,
      firstName: this.state.first_name,
      lastName: this.state.last_name,
      redirect,
      visit_guid: s?.eVar66 || null,
    });
  };

  getButton = () => {
    if (this.props.submitting) {
      return (
        <UIButton disabled type="submit">
          <FormattedMessage {...messages.submitting} />
        </UIButton>
      );
    }

    return (
      <UIButton type="submit" disabled={!(this.state.last_name && this.state.first_name && this.state.email)}>
        <FormattedMessage {...messages.signUp} />
      </UIButton>
    );
  };
  getTermPanel = () => {
    return  (
        <div className="accept-term__container">
          <p>
            <FormattedMessage {...termsMessages.termstext_part1} />
          </p>
          <p>
            <FormattedMessage
                {...termsMessages.termstext_part2}
                values={{
                  ua: UserAgreementLink,
                  pp: PrivacyNoticeLink
                }}
            />
          </p>
        </div>
    );
  };
  handleFirstNameValidate = (errors) => {
    this.setState({
      showError: false,
      errors: {
        ...this.state.errors,
        first_name: errors,
      },
    });
  };
  handleLastNameValidate = (errors) => {
    this.setState({
      showError: false,
      errors: {
        ...this.state.errors,
        last_name: errors,
      },
    });
  };
  getErrors = (errorMsgKey) => {
    if (errorMsgKey) {
      return <FormattedMessage {...messages[errorMsgKey]} />;
    }
    const errorTypes = Object.keys(this.state.errors);
    for (let i = 0; i < errorTypes.length; i++) {
      const errorArr = this.state.errors[errorTypes[i]];
      /* istanbul ignore next */
      if (errorArr && Object.prototype.toString.call(errorArr) === '[object Array]' && errorArr.length > 0) {
        return errorArr[0].errorMessage;
      } else if (errorArr && Object.prototype.toString.call(errorArr) !== '[object Array]' && errorArr !== '') {
        return errorArr;
      }
    }
  };
  handleOnChange = (field) => (value) => {
    const values = {
      [field]: value,
    };
    const errors = {...this.state.errors, ...validate(values)};
    const {handleOnChange} = this.props;

    handleOnChange && handleOnChange(field, value);
    this.setState({
      [field]: value,
      errors,
      showError: Object.values(errors).filter((it) => it).length > 0,
    });
  };
  render() {
    const {intl, errorMsgKey} = this.props;
    const {globalRegistry} = this.context;
    const hideOptIn = globalRegistry && globalRegistry.getFeatureValue('common.register.uncheckMarketingOptIn');
    const {email, first_name, last_name} = this.state;
    const {showError} = this.props;

    return (
      <form name="register-form" id="register-form" onSubmit={this.onSubmit}>
        {showError && <LoginAlert>{this.getErrors(errorMsgKey)}</LoginAlert>}
        <Row>
          <Col md={6} xs={6}>
            <Input
              {...{
                id: 'first-name',
                onChange: this.handleOnChange('first_name'),
                label: intl.formatMessage(messages.firstName),
                type: 'text',
                autoCapitalize: 'on',
                value: first_name,
                tabIndex: 1,
                name: 'first_name',
                autoComplete: 'given-name',
                className: 'address-form__input address-form__group-input address-form__group-input--first-child',
              }}
            />
          </Col>
          <Col md={6} xs={6}>
            <Input
              {...{
                id: 'last-name',
                type: 'text',
                autoComplete: 'family-name',
                autoCapitalize: 'on',
                name: 'last_name',
                onChange: this.handleOnChange('last_name'),
                label: intl.formatMessage(messages.lastName),
                value: last_name,
                tabIndex: 2,
                className: 'address-form__input address-form__group-input address-form__group-input--last-child',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Input
              label={intl.formatMessage(messages.emailPlaceHolder)}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              autoCapitalize="off"
              onChange={this.handleOnChange('email')}
              value={email}
              tabIndex={3}
            />
          </Col>
        </Row>
        {hideOptIn && (
          <Row middle="md">
            <Col md={12} xs={12}>
              <Checkbox
                label={intl.formatMessage(messages.email_box)}
                name="marketing_email_opt_in"
                handleCheckboxChange={this.handleCheckboxChange}
              />
            </Col>
          </Row>
        )}
        {this.getButton()}
        {this.getTermPanel()}
      </form>
    );
  }
}

export const ExternalLinks = (props) => {
  return (
    <div className="links__container">
      <span>
        <FormattedMessage {...messages.returnTo} />
      </span>
      <FormattedLink className="auth-link signin formattedlink_without_href" onClick={props.onSignInClick}>
        <FormattedMessage {...messages.signIn} />
      </FormattedLink>
    </div>
  );
};

export const PageHeader = ({intl, socialType}) => (
  <LoginHeader title={intl.formatMessage(messages.signUpTitle, {socialType})}>
    <FormattedMessage
      {...messages.signUpTitle}
      values={{socialType: <span className="socialType">{socialType}</span>}}
    />
  </LoginHeader>
);

export const SocialSignUpLayout = (SignUpForm) => {
  class WrappedComponent extends Component {
    static contextTypes = {
      cookies: PropTypes.object,
      track: PropTypes.object,
    };
    static propTypes = {
      socialButtonEnabled: PropTypes.bool,
      renderSocialLogin: PropTypes.func,
    };
    static defaultProps = {
      socialButtonEnabled: true,
    };

    UNSAFE_componentWillMount() {
      /* istanbul ignore else */
      if (__CLIENT__ && window.sessionStorage) {
        let fbCredentials = sessionStorage.getItem('session_fbCredentials') || '{}';
        fbCredentials = JSON.parse(fbCredentials);
        this.userData = fbCredentials;
      }
    }

    render() {
      return (
        <Row
          className={cx('central-login-signup__layout', {
            'signup__layout--socialEnabled': !this.props.socialButtonEnabled,
          })}
        >
          <Col lg={12}>
            <section className="signup__container">
              <PageHeader intl={this.props.intl} socialType={this.props.idpType} />
              <ExternalLinks {...this.props} />
              <div className="signup__form-wrapper">
                <SignUpForm {...this.props} {...this.userData} cookies={this.context.cookies} />
              </div>
            </section>
          </Col>
        </Row>
      );
    }
  }
  SocialSignUpLayout.displayName = `SignUpLayout(${SignUpForm.displayName || SignUpForm.name || 'SignUpPageComponent'}`;

  return injectIntl(WrappedComponent);
};

export const ConnectedSignUpForm = connect(controller)(SocialSignUpForm);
export default SocialSignUpLayout(ConnectedSignUpForm);
