const FEATURE_FLAGS = {
  common: {
    advisory: {
      /** Is advisory currency enabled? */
      enabled: 'common.enableAdvisoryCurrency',
    },

    /** Is cookies modal enabled? */
    cookiesModal: 'common.cookiesModal.show',
  },
  login: {
    social: {
      /** Is google login enabled? */
      google: 'login.enableGoogleLogin',
    },

    /** VoiceFlow Chatbot enabled */
    voiceFlow: 'login.voiceflow.enable',
  },
};

export default FEATURE_FLAGS;
