import {defineMessages} from 'react-intl';

const messages = defineMessages({
  firstName: {
    id: 'CentralLogin.signUp.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'CentralLogin.signUp.lastName',
    defaultMessage: 'Last Name',
  },
  emailPlaceHolder: {
    id: 'CentralLogin.signUp.emailPlaceHolder',
    defaultMessage: 'Email',
  },
  passwordPlaceHolder: {
    id: 'CentralLogin.signUp.passwordPlaceHolder',
    defaultMessage: 'Password',
  },
  submitting: {
    id: 'CentralLogin.signUp.isSubmitting',
    defaultMessage: 'One Moment...',
  },
  signUp: {
    id: 'CentralLogin.signUp.submitButton',
    defaultMessage: 'Create account',
  },
  signIn: {
    id: 'CentralLogin.signUp.signInLink',
    defaultMessage: 'Sign in',
  },
  haveStubHubAccount: {
    id: 'CentralLogin.signUp.haveStubHubAccount',
    defaultMessage: 'Already have one?',
  },
  title: {
    id: 'CentralLogin.signUp.title',
    defaultMessage: 'Create Account',
  },
  emailBox: {
    id: 'CentralLogin.signUp.emailSignUp',
    defaultMessage: 'Send me emails about upcoming events',
  },
  maxLengthHint: {
    id: 'CentralLogin.signUp.name.length.error',
    defaultMessage: 'Your name needs to be {maxLength} characters only!',
  },
  emptyfirstname: {
    id: 'CentralLogin.signUp.emptyFirstName',
    defaultMessage: 'Whoops! We need first name.',
  },
  emptylastname: {
    id: 'CentralLogin.signUp.emptyLastName',
    defaultMessage: 'Whoops! We need last name.',
  },
  emptypassword: {
    id: 'CentralLogin.signUp.emptyPassword',
    defaultMessage: 'Whoops! We need a password.',
  },
  genericError: {
    id: 'CentralLogin.signUp.genericError',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
  accountExists: {
    id: 'CentralLogin.signUp.accountExists',
    defaultMessage: 'Looks like your email address is already registered with StubHub.',
  },
  emailPwdError: {
    id: 'CentralLogin.register.emailPWDError',
    defaultMessage: 'Enter a valid Email and Password',
  },
  invalidPassword: {
    id: 'CentralLogin.register.invalidPWD',
    defaultMessage:
      'Oops! Your password needs to be 8-20 characters; a combo of numbers, letters, and/or special characters; and cannot be your email address.',
  },
  phonePlaceHolder: {
    id: 'CentralLogin.register.phonePlaceHolder',
    defaultMessage: 'Phone Number (Optional)',
  },
});

export default messages;
