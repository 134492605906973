import {defineMessages} from 'react-intl'

const termsMessages = defineMessages({
    termstext_intl: {
        id: 'CentralLogin.signUp.termsTextIntl',
        defaultMessage: 'By signing in, you agree you have read and accepted our <ua>User Agreement</ua>. Please read our <pp>Privacy Notice</pp> for information regarding the processing of your data.',
    },
    termstext_part1: {
        id: 'CentralLogin.signUp.termsText.part1',
        defaultMessage: 'Your data will be used to create and manage your StubHub International account. We may also send you updates and offers; you can unsubscribe at any time using the link in our emails.',
    },
    termstext_part2: {
        id: 'CentralLogin.signUp.termsText.part2',
        defaultMessage:
            'By creating an account, you agree you have read and accepted our <ua>User Agreement</ua>. Please read our <pp>Privacy Notice</pp> regarding the processing of your data.',
    },
});

export default termsMessages;